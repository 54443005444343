<template>
  <el-button
    class="back-to-top-button"
    type="text"
    size="mini"
    circle
    @click="back"
  >
    <div
      class="back-to-top-button-circle"
      v-if="state.show"
    >
      <i class="el-icon-caret-top" />
      <span class="text">
        TOPO
      </span>
    </div>
  </el-button>
</template>

<script>
import { reactive } from 'vue'

export default {
  setup () {
    const state = reactive({
      show: false
    })

    const back = (_event) => window.scrollTo(0, 0)

    window.addEventListener('scroll', (_event) => {
      const scrollTop = Number(document.scrollingElement.scrollTop)
      const scrollTopMax = Number(document.documentElement.scrollHeight)

      const scrollPercentage = ((100 * scrollTop) / scrollTopMax).toFixed(0)

      state.show = scrollPercentage >= 10
    })

    return {
      state,
      back
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/core.scss';

.back-to-top-button {
  position: fixed;
  bottom: 44px;
  right: 24px;
  z-index: 16;

  @include sm {
    right: 16px;
  }

  .back-to-top-button-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    height: 40px;
    width: 40px;
    background-color: $--color-secondary;
    border-radius: 4px;
    color: #fff;

    i {
      font-size: 20px;
    }

    .text {
      font-weight: 800;
      font-size: 10px;
      margin: 0;
    }

    /* span {
      position: absolute;
      bottom: 5px;
      left: 5px;
      font-size: 7px;
    } */
  }
}
</style>
