import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, _from, next) => {
  document.title = to.meta.title

  if (to.path === '/termos-de-uso') {
    window.location.href = 'https://www.projuris.com.br/termos-de-uso-projuris-acordos'
    next(false)
  } else {
    next()
  }
})

export default router
