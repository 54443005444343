import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'

import VueTheMask from 'vue-the-mask'

const app = createApp(App)

app.use(store).use(router).use(ElementPlus).use(VueTheMask).mount('#app')
