<template>
  <el-container class="home-container">
    <el-main class="home-container__main">
      <router-view/>

      <BackToTop />
    </el-main>
  </el-container>
</template>

<script>
import BackToTop from '@/components/BackToTop.vue'

import { onBeforeMount, onBeforeUnmount, h } from 'vue'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'

export default {
  components: {
    BackToTop
  },

  setup () {
    const store = useStore()

    let notificationInstance = null

    function setWidthToStore ({ target: { innerWidth } }) {
      store.commit('setWindowWidth', innerWidth)
    }

    function setCookie (name, value, time) {
      let t = ''

      if (time) {
        const n = new Date()
        n.setTime(n.getTime() + 24 * time * 60 * 60 * 1e3)
        t = `; expires=${n.toUTCString()}`
      }

      document.cookie = name + '=' + (value || '') + t + '; path=/'
    }

    function closeNotification () {
      if (notificationInstance) {
        notificationInstance.close()
      }
    }

    function acceptCookies () {
      setCookie('acceptedJusttoCookies', true, 7)
      closeNotification()
    }

    onBeforeMount(() => {
      setWidthToStore({ target: window })

      window.addEventListener('resize', setWidthToStore)

      const content = h('div', {
        style: {
          display: 'flex',
          flexDirection: 'column',
          gap: '4px'
        }
      }, [
        h('p', {}, [
          h('span', {}, 'Nós armazenamos dados temporariamente para melhorar a experiência de navegação e recomendar conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com tal monitoramento. '),
          h('a', {
            href: 'https://justto.com.br/politica-privacidade',
            target: '_blank'
          }, 'Para que?')
        ]),
        h('button', {
          class: 'el-button el-button--default el-button--medium el-button--secondary font-weight-600 medium-btn',
          type: 'button',
          onclick: acceptCookies
        }, 'Aceitar')
      ])

      if (!(document.cookie.includes('acceptedJusttoCookies=true'))) {
        notificationInstance = ElNotification({
          title: 'Esse site usa cookies',
          dangerouslyUseHTMLString: true,
          message: content,
          customClass: 'cookies-notification',
          position: 'bottom-right',
          duration: 0,
          showClose: false
        })
      }
    })

    onBeforeUnmount(() => window.removeEventListener('resize', setWidthToStore))
  }
}
</script>

<style lang="scss">
@import '@/styles/core.scss';

#app {
  .home-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .home-container__header {
      height: 7.5vh;
    }

    .home-container__main {
      overflow: visible;
      min-height: 55vh;
      padding-right: 0;
      padding-bottom: 0;
      padding: 0 !important;
    }

    .home-container__footer {
      width: 100%;
      min-height: 20vh;
    }
  }

  @include sm {
    .home-container {
      .home-container__main {
        padding: 0 !important;
      }
    }
  }
}

.cookies-notification {
  border-radius: 0 !important;
  background: $--color-black !important;
  overflow: hidden !important;
  border: none !important;
  width: 40vw !important;

  .el-notification__group {
    margin: 0;

    .el-notification__title {
      color: $--color-white;
    }

    .el-notification__content {
      color: $--color-white;

      a {
        color: $--color-white;
        text-decoration: underline;
      }
    }
  }
}
</style>
