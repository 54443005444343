export default {
  state: () => ({
    width: 0
  }),

  mutations: {
    setWindowWidth (state, width) {
      state.width = width
    }
  },

  getters: {
    getWindowWidth (state) {
      return state.width
    },

    getWindowMode (state) {
      return state.width <= 800
        ? 'sm'
        : (
            state.width <= 1000
              ? 'md'
              : (
                  state.width <= 1400 ? 'lg' : 'xlg'
                )
          )
    }
  }
}
