const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
    meta: { title: 'ProJuris - Início' }
  },
  {
    path: '/index',
    redirect: '/'
  },
  {
    path: '/home',
    redirect: '/'
  },
  {
    path: '/termos-de-uso',
    name: 'Termos de Uso',
    meta: { title: 'ProJuris - Termos de Uso' },
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/docs/PrivacyPolicy.vue')
  },
  {
    path: '/poilitica-de-privacidade',
    redirect: '/termos-de-uso'
  },
  {
    path: '/poilitica-privacidade',
    redirect: '/termos-de-uso'
  },
  {
    path: '/politica-de-privacidade',
    redirect: '/termos-de-uso'
  },
  {
    path: '/politica-privacidade',
    redirect: '/termos-de-uso'
  },
  {
    path: '/codigo-protecao-dados-pessoais',
    redirect: '/termos-de-uso'
  },
  {
    path: '/termos-de-contratacao',
    redirect: '/termos-de-uso'
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

module.exports = routes
